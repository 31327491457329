<template>
  <main>
    <div class="content_wrapper">
      <div class="annex">
        <div class="content finish">
          <img src="../assets/images/logo.jpg" /><br />
          Bienvenue sur le site de l'offre BLUE PURE GROHE !<br />

          <p>
            L'offre n'est pas encore ouverte. Encore un peu de patience !
            <br /><br />
            Rendez-vous le 1er avril 2021
            <br /><br />
            <span>L'équipe GROHE</span>
          </p>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "wait",
};
</script>

					
												
												
												
												
												
